import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './container/NavBar';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';
import SearchPage from 'container/SearchPage';
import FloorDetailsPage from 'container/FloorDetailsPage';
import InteractiveMap from 'container/InteractiveMap';
import HomeOfTheWeek from 'container/HomeOfTheWeek';


const HomePage = React.lazy(() => import('./container/Home'));
const RoomDetailPage = React.lazy(() => import('./component/RoomDetail'));
const PageTemplate = React.lazy(() => import('./component/PageTemplate'));
const ContactPage = React.lazy(() => import('./container/Contact'));
const PolicyPage = React.lazy(() => import('./container/Policy'));
// const RoomsPage = React.lazy(() => import('./container/Rooms'));
// const GroupContactForm = React.lazy(() => import('./component/ContactForm'));

function App({ cmsData }) {
  const { 'core-data': coreData } = cmsData;

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar CoreData={coreData} />
        <Switch>
          {/* <Route path="/rooms/:slug" component={() => <RoomDetailPage />} />
          <Route path="/location" component={() => <LocationPage />} /> */}
          <Route path="/contact-us" component={() => <ContactPage />} />
          <Route path="/pages/interactive-map" component={() => <InteractiveMap />} />
          <Route path="/pages/search-homes" component={() => <SearchPage />} />
          <Route path="/pages/homeoftheweek" component={()=> <HomeOfTheWeek />} />
          <Route path="/pages/homes/:homeid" component={() => <FloorDetailsPage />} />
          {/* <Route path="/policies" component={() => <PolicyPage />} /> */}
          <Route path="/pages/:slug" component={() => <PageTemplate />} />
          <Route exact path="/" component={() => <HomePage />} />
          <Route path="*" render={() => { return <Redirect to="/" ></Redirect> }}></Route>
        </Switch>
        <Footer cmsData={{ coreData }} />
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ['core-data']);
