import React, { useState } from 'react';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { Link, useLocation, withRouter } from 'react-router-dom';

import './style.css';
import { Img } from 'component/FrameL';
import Accordion from 'component/Accordion';


function MobileNavbar({ CoreData }) {
  const { navigation, logo } = CoreData.attributes;
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (<div className="fixed z-20 text-white">

    <div className={`fixed mt-4 ml-4 top-0 left-0 flex flex-row items-start w-full h-0`}>
      <button className={`p-1 bg-primary-blue`} onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="text-4xl"></IoMdMenu>
      </button>

      {<Img Image={logo.data} className='mx-auto w-40' />}
    </div>
    <div className={`px-4 bg-primary-blue stack-l mobile-nav__container ${isOpen ? 'visible' : ''}`} style={{ '--justify': 'start' }}>
      <button className='absolute top-0 right-0'>
        <IoMdClose className="icon m-4" onClick={() => {
          setIsOpen(false);
        }} />
      </button>

      <Link className='mx-auto' to="/" onClick={() => {
        setIsOpen(false);
      }}>
        <Img Image={logo.data} />
      </Link>

      {navigation.map((item, i) => {
        const { main, sublinks } = item;

        const { displayText, url } = main;

        return <Accordion title={() => {
          return <button className='no-underline'>
            {displayText}
          </button>
        }}>
          <div className='pl-8 mt-2 stack-l' style={{
            '--space': 'var(--s-3)'
          }}>
            {sublinks.map((item, i) => {
              const { displayText, url } = item;
              return <Link className='no-underline' key={i} to={url} onClick={() => {
                setIsOpen(false);
              }}>{displayText}</Link>
            })}
          </div>
        </Accordion>
      })}
    </div>
  </div>
  );
}

const NavBar = ({ CoreData }) => {
  return (
    <MobileNavbar CoreData={CoreData} />
  );
}

export default withRouter(NavBar);