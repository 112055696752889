import FloorDetailsPage from 'container/FloorDetailsPage';
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';


const HomeOfTheWeek = () => {
    const [homeId, setHomeId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_DOMAIN}/api/inventories?filters[HomeOfTheWeek]=true&fields[0]=id`)
            .then(resp => resp.json())
            .then(newData => {
                setIsLoading(false);
                console.log("homeof the weeks", newData.data[0].id);
                if (newData.data[0].id) {
                    setHomeId(newData.data[0].id);
                } else {
                    setHomeId(0);
                }
            }).catch(error => {
                //setData({});
                setIsLoading(false);
            });
    },[]);

    if(!isLoading && homeId != null){
            return <FloorDetailsPage details={{"homeid":homeId}} />
    } 

}

export default HomeOfTheWeek;