import React from 'react';
import PropTypes from 'prop-types';

import { LazyLoadImage as Img } from 'react-lazy-load-image-component';

const BELOW_SMALL = window.screen.width < 640;
const BELOW_MEDIUM = window.screen.width < 768;
const BELOW_LARGE = window.screen.width < 1024;

const FrameImage = ({ isExternal = true, optimizeImageSize = true, Image, src = '', alt, className = '', height, width, ...props }) => {
    if (Image && Image.attributes) {
        alt = Image.attributes.alternativeText
        src = Image.attributes.url;
        height = Image.attributes.height;
        width = Image.attributes.width;

        if (optimizeImageSize) {
            if (BELOW_SMALL) {
                src = Image.attributes.formats?.small?.url || src;
            } else if (BELOW_MEDIUM) {
                src = Image.attributes.formats?.medium?.url || src;
            } else if (BELOW_LARGE) {
                src = Image.attributes.formats?.large?.url || src;
            }
        }
    }
    let finalSrc = src;

    if (isExternal) {
        finalSrc = `${process.env.REACT_APP_API_DOMAIN}${src}`;
    }

    if (!src) {
        return <span className={`${className} bg-gray-300 text-white text-xl`}>Image Not Available</span>
    }

    return <Img threshold={200} height={height} width={width} placeholder={<span className="bg-gray-300 text-white text-xl">Image Loading</span>} className={`${className}`} src={finalSrc} alt={alt} {...props}></Img>
}

const FrameL = ({ children, className = '', Image, src, alt, isExternal = true, imgClasses = '', hasImage = true, ratio = '16:9', fixedHeight, ratioAboveSm, ratioAboveMd, ratioAboveLg, ratioAboveXl, optimizeImageSize = true }) => {
    const isVideo = Image?.attribues?.mime === 'video/mp4';
    const style = {
    }

    if (fixedHeight) {
        style.height = fixedHeight;
        ratio = '1:0';
    }

    if (ratio) {
        const [n, d] = ratio.split(':');
        style['--n'] = n;
        style['--d'] = d;
    }

    if (ratioAboveSm) {
        const [n, d] = ratioAboveSm.split(':');
        style['--nAboveSm'] = n;
        style['--dAboveSm'] = d;
    }

    if (ratioAboveMd) {
        const [n, d] = ratioAboveMd.split(':');
        style['--nAboveMd'] = n;
        style['--dAboveMd'] = d;
    }

    if (ratioAboveLg) {
        const [n, d] = ratioAboveLg.split(':');
        style['--nAboveLg'] = n;
        style['--dAboveLg'] = d;
    }

    if (ratioAboveXl) {
        const [n, d] = ratioAboveXl.split(':');
        style['--nAboveXl'] = n;
        style['--dAboveXl'] = d;
    }

    return <div
        style={style}
        className={`frame-l ${className}`}>
        {isVideo && <video src={process.env.REACT_APP_API_DOMAIN + Image.attributes.url + "#t=0.3"} preload="metadata" controls></video>}
        {!isVideo && hasImage && <FrameImage optimizeImageSize={optimizeImageSize} className={imgClasses} Image={Image} src={src} alt={alt} isExternal={isExternal}></FrameImage>}
        {children}
    </div>
};

FrameL.propTypes = {
    src: PropTypes.string,
    isExternal: PropTypes.bool,
};

FrameL.defaultProps = {
    src: '',
    isExternal: true,
    alt: '',
    className: '',
};

export default FrameL;
export { FrameImage as Img };

