import BackgroundImage from 'component/BackgroundImage';
import React from 'react';
import './style.css';
import { createRef } from 'react';
import { useState } from 'react';
const getImage = (name) => {
    const img = require('../../assets/map-images/' + name);
    return img;
}

const InteractiveMap = () => {
    const [dummyState, resetDummyState] = useState();
    const handleLegendClick = (e) => {
        const { classList } = e.target;
        if (classList.contains('plus')) {
            classList.add('minus');
            classList.remove('plus');
            var showElem = document.getElementById('comm' + e.target.getAttribute('name'));
            if (showElem) {
                showElem.classList.remove('hiddenElem');
            } else if (e.target.getAttribute('name') == 'ModelHomes') {
                var allElems = document.getElementsByClassName('modelhomes');
                for (var i = 0; i < allElems.length; i++) {
                    allElems[i].classList.remove('hiddenElem');
                }
            } else if (e.target.getAttribute('name') == 'Trails') {
                document.getElementById('Trails').classList.remove('hiddenElem');
            }
        } else if (classList.contains('minus')) {
            classList.remove('minus');
            classList.add('plus');
            var hideElem = document.getElementById('comm' + e.target.getAttribute('name'));
            if (hideElem) {
                hideElem.classList.add('hiddenElem');
            } else if (e.target.getAttribute('name') == 'ModelHomes') {
                var allElems = document.getElementsByClassName('modelhomes');
                for (var i = 0; i < allElems.length; i++) {
                    allElems[i].classList.add('hiddenElem');
                }
            }
            else if (e.target.getAttribute('name') == 'Trails') {
                console.log(e.target.getAttribute('name'));
                document.getElementById('Trails').classList.add('hiddenElem');
            }
        } else {
            console.log('nothing found');
        }
        resetDummyState(new Date().getTime());
    }
    const getToggleImage = (elemId) => {
        var element = document.getElementById(elemId);
        if (element && element.classList.contains('minus')) {
            return getImage('minus.png');
        } else {
            return getImage('plus.png');
        }

    }

    return <div>
        <div className='modal text-base'>
            <span>Now Selling in the green area top left of this section.</span>
        </div>
        <BackgroundImage className='h-screen' id="mapLayer" isExternal={false} Image={getImage('tree-texture.jpg')}>
            <div id="legendMP">
                <h1>Legend</h1>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage('boat-ramp.png')} width="24" height="24" />
                    </div>
                    <div className="descLegendElement">
                        Boat Ramp
                    </div>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("dog-park.png")} width="24" height="24" />
                    </div>
                    <div className="descLegendElement">
                        Dog Park
                    </div>
                </div>
                <div className="legendElement" name="ModelHomes" onClick={handleLegendClick}>
                    <BackgroundImage className="plus" name="ModelHomes" id="le1" isExternal={false} Image={getToggleImage('le1')}>Model Homes</BackgroundImage>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("sales-center.png")} width="24" height="24" />
                    </div>
                    <div className="descLegendElement">
                        Sales Center
                    </div>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("school-books.png")} />
                    </div>
                    <div className="descLegendElement">
                        Schools
                    </div>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("sunset-park.png")} width="24" height="24" />
                    </div>
                    <div className="descLegendElement">
                        Sunset Park
                    </div>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("lake-house.png")} />
                    </div>
                    <div className="descLegendElement">
                        The Lake House
                    </div>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("town-center.png")} />
                    </div>
                    <div className="descLegendElement">
                        Town Center
                    </div>
                </div>
                <div className="legendElement dynamic" name="Trails" onClick={handleLegendClick} >
                    <BackgroundImage className="plus" id='le2' name="Trails" isExternal={false} Image={getToggleImage('le2')}>Trails</BackgroundImage>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("village-green.png")} />
                    </div>
                    <div className="descLegendElement">
                        Village Green
                    </div>
                </div>
                <div className="legendElement">
                    <div className="imgLegendElement">
                        <img src={getImage("YMCA.png")} />
                    </div>
                    <div className="descLegendElement">
                        YMCA
                    </div>
                </div>
            </div>
            <div id="NeighborhoodsPanel" onClick={handleLegendClick}>
                <div>Neighborhoods</div>
                <BackgroundImage name="22" className="plus" isExternal={false} id='np22' Image={getToggleImage('np22')}>Arbor Place</BackgroundImage>
                <BackgroundImage name="1" className="plus" isExternal={false} id='np1' Image={getToggleImage('np1')}>Ashland Downes</BackgroundImage>
                <BackgroundImage name="2" className="plus" isExternal={false} id='np2' Image={getToggleImage('np2')}>Ashland Falls</BackgroundImage>
                <BackgroundImage name="3" className="plus" isExternal={false} id='np3' Image={getToggleImage('np3')}>Ashland Grove</BackgroundImage>
                <BackgroundImage name="42" className="plus" isExternal={false} id='np42' Image={getToggleImage('np42')}>Ashland Mills</BackgroundImage>
                <BackgroundImage name="41" className="plus" isExternal={false} id='np41' Image={getToggleImage('np41')}>Ashland Park</BackgroundImage>
                <BackgroundImage name="39" className="plus" isExternal={false} id='np39' Image={getToggleImage('np39')}>Ashton Hill</BackgroundImage>
                <BackgroundImage name="23" className="plus" isExternal={false} id='np23' Image={getToggleImage('np23')}>Berkeley Hall</BackgroundImage>
                <BackgroundImage name="4" className="plus" isExternal={false} id='np4' Image={getToggleImage('np4')}>Berkeley Landing</BackgroundImage>
                <BackgroundImage name="13" className="plus" isExternal={false} id='np13' Image={getToggleImage('np13')}>Berkeley Manor</BackgroundImage>
                <BackgroundImage name="16" className="plus" isExternal={false} id='np16' Image={getToggleImage('np16')}>Berkeley Shores</BackgroundImage>
                <BackgroundImage name="26" className="plus" isExternal={false} id='np26' Image={getToggleImage('np26')}>Bluffs</BackgroundImage>
                <BackgroundImage name="27" className="plus" isExternal={false} id='np27' Image={getToggleImage('np27')}>Brookshire</BackgroundImage>
                <BackgroundImage name="28" className="plus" isExternal={false} id='np28' Image={getToggleImage('np28')}>Cambridge Hills</BackgroundImage>
                <BackgroundImage name="40" className="plus" isExternal={false} id='np40' Image={getToggleImage('np40')}>Canterbury Park</BackgroundImage>
                <BackgroundImage name="5" className="plus" isExternal={false} id='np5' Image={getToggleImage('np5')}>Centennial</BackgroundImage>
                <BackgroundImage name="44" className="plus" isExternal={false} id='np44' Image={getToggleImage('np44')}>Centennial Lakes</BackgroundImage>
                <BackgroundImage name="21" className="plus" isExternal={false} id='np21' Image={getToggleImage('np21')}>Elliot Lake</BackgroundImage>
                <BackgroundImage name="6" className="plus" isExternal={false} id='np6' Image={getToggleImage('np6')}>Executive Courtyards</BackgroundImage>
                <BackgroundImage name="7" className="plus" isExternal={false} id='np7' Image={getToggleImage('np7')}>Harborside</BackgroundImage>
                <BackgroundImage name="14" className="plus" isExternal={false} id='np14' Image={getToggleImage('np14')}>Laurel Chase</BackgroundImage>
                <BackgroundImage name="31" className="plus" isExternal={false} id='np31' Image={getToggleImage('np31')}>LC Manor</BackgroundImage>
                <BackgroundImage name="32" className="plus" isExternal={false} id='np32' Image={getToggleImage('np32')}>Magnolia Bluff</BackgroundImage>
                <BackgroundImage name="8" className="plus" isExternal={false} id='np8' Image={getToggleImage('np8')}>Manchester Park</BackgroundImage>
                <BackgroundImage name="33" className="plus" isExternal={false} id='np33' Image={getToggleImage('np33')}>Mariner's Cove</BackgroundImage>
                <BackgroundImage name="34" className="plus" isExternal={false} id='np34' Image={getToggleImage('np34')}>Meadows</BackgroundImage>
                <BackgroundImage name="18" className="plus" isExternal={false} id='np18' Image={getToggleImage('np18')}>Pinnacle Ridge </BackgroundImage>
                <BackgroundImage name="10" className="plus" isExternal={false} id='np10' Image={getToggleImage('np10')}>Sandy Glen</BackgroundImage>
                <BackgroundImage name="35" className="plus" isExternal={false} id='np35' Image={getToggleImage('np35')}>Summerlin</BackgroundImage>
                <BackgroundImage name="45" className="plus" isExternal={false} id='np45' Image={getToggleImage('np45')}>Summerlin by the Lake</BackgroundImage>
                <BackgroundImage name="36" className="plus" isExternal={false} id='np36' Image={getToggleImage('np36')}>Tennyson</BackgroundImage>
                <BackgroundImage name="20" className="plus" isExternal={false} id='np20' Image={getToggleImage('np20')}>The Lakes</BackgroundImage>
                <BackgroundImage name="37" className="plus" isExternal={false} id='np37' Image={getToggleImage('np37')}>The Oaks</BackgroundImage>
                <BackgroundImage name="9" className="plus" isExternal={false} id='np9' Image={getToggleImage('np9')}>The Peninsula</BackgroundImage>
                <BackgroundImage name="47" className="plus" isExternal={false} id='np47' Image={getToggleImage('np47')}>The Ponds</BackgroundImage>
                <BackgroundImage name="46" className="plus" isExternal={false} id='np46' Image={getToggleImage('np46')}>The Ponds at Maple Lake</BackgroundImage>
                <BackgroundImage name="11" className="plus" isExternal={false} id='np11' Image={getToggleImage('np11')}>Waterford Corners</BackgroundImage>
                <BackgroundImage name="15" className="plus" isExternal={false} id='np15' Image={getToggleImage('np15')}>Willow Pointe</BackgroundImage>
                <BackgroundImage name="17" className="plus" isExternal={false} id='np17' Image={getToggleImage('np17')}>Woodcrest</BackgroundImage>
                <BackgroundImage name="12" className="plus" isExternal={false} id='np12' Image={getToggleImage('np12')}>Woodleigh Park</BackgroundImage>
                <BackgroundImage name="19" className="plus" isExternal={false} id='np19' Image={getToggleImage('np19')}>Woodleigh Pointe</BackgroundImage>
                <BackgroundImage name="38" className="plus" isExternal={false} id='np38' Image={getToggleImage('np38')}>Woodridge</BackgroundImage>
            </div>
            <BackgroundImage id="mapContainer" isExternal={false} Image={getImage('MasterPlan-FINAL.jpg')}>
                <BackgroundImage isExternal={false} Image={getImage('YMCA.png')} className="elements" id="YMCA"><div className="elemLabel">Jeep Rogers<br /> Family YMCA</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('school-books.png')} className="elements school" id="LCElementary"><div className="elemLabel">Lake Carolina Elementary School</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('school-books.png')} className="elements school" id="NewSchool"><div className="elemLabel">Lake Carolina Elementary Lower Campus</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('school-books.png')} className="elements school" id="LCMiddle"><div className="elemLabel">Kelly Mill <br /> Middle School</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('town-center.png')} className="elements" id="TownCenter"><div className="elemLabel">Town<br /> Center</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('village-green.png')} className="elements" id="VillageGreen"><div className="elemLabel">Village<br /> Green</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('sales-center.png')} className="elements" id="SalesCenter"><div className="elemLabel" style={{ top: '12px', left: '56px' }}>Sales<br /> Center</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('dog-park.png')} className="elements" id="DogPark"><div className="elemLabel">Dog <br /> Park</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('sunset-park.png')} className="elements" id="SunsetPark"><div className="elemLabel">Sunset <br /> Park</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('boat-ramp.png')} className="elements" id="BoatRamp"><div className="elemLabel">Boat <br /> Ramp</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('lake-house.png')} className="elements" id="LakeHouse"><div className="elemLabel">The Lake <br /> House</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('lake-house.png')} className="elements" id="areaLake"><div className="elemLabel">The Lake <br /> Coming Soon New Lake Lots</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('hiking-trails.png')} className="elements hiddenElem" id="Trails"></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('model-homes.png')} className="elements modelhomes hiddenElem" id="mh1"></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('model-homes.png')} className="elements modelhomes hiddenElem" id="mh3"></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('model-homes.png')} className="elements modelhomes hiddenElem" id="mh4"></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('model-homes.png')} className="elements modelhomes hiddenElem" id="mh5"></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('model-homes.png')} className="elements modelhomes hiddenElem" id="mh2"></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm22"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Arbor Place</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm1" ><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Ashland Downes</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm2"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Ashland Falls</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm3"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Ashland Grove</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm42"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Ashland Mills</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm41"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Ashland Park</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm39"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Ashton Hill</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm23"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Berkeley Hall</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm4"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Berkeley Landing</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm13"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Berkeley Manor</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm16"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Berkeley Shores</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm26"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Bluffs</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm27"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Brookshire</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm28"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Cambridge Hills</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm40"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Canterbury Park</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm5"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Centennial</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm44"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Centennial Lakes</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm21"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Elliot Lake</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm6"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Executive Courtyards</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm7"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Harborside</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm14"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Laurel Chase</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm31"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>LC Manor</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm32"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Magnolia Bluff</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm8"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Manchester Park</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm33"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Mariner's Cove</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm34"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Meadows</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm18"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Pinnacle Ridge </div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm10"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Sandy Glen</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm35"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Summerlin</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm45"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Summerlin by the Lake</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm36"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Tennyson</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm20"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>The Lakes</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm37"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>The Oaks</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm9"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>The Peninsula</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm47"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>The Ponds</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm46"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>The Ponds at Maple Lake</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm11"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Waterford Corners</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm15"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Willow Pointe</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm17"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Woodcrest</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm12"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Woodleigh Park</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm19"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Woodleigh Pointe</div></BackgroundImage>
                <BackgroundImage isExternal={false} Image={getImage('commImage.png')} className="text-white elements neighborhoodsDot hiddenElem" id="comm38"><div className="elemLabel" style={{ paddingTop: '4px', width: '200px' }}>Woodridge</div></BackgroundImage>
            </BackgroundImage>
        </BackgroundImage>

    </div>
}

export default InteractiveMap;
