import React, { Children } from 'react';
import PropTypes from 'prop-types';

import { LazyLoadImage as Img } from 'react-lazy-load-image-component';

const BELOW_SMALL = window.screen.width < 640;
const BELOW_MEDIUM = window.screen.width < 768;
const BELOW_LARGE = window.screen.width < 1024;

const BackgroundImage = ({ children , isExternal = true, optimizeImageSize = true, Image, src = '', alt, className = '', height, width, ...props }) => {
    
    if (Image && Image.attributes) {
        alt = Image.attributes.alternativeText
        src = Image.attributes.url;
        height = Image.attributes.height;
        width = Image.attributes.width;

        if (optimizeImageSize) {
            if (BELOW_SMALL) {
                src = Image.attributes.formats?.small?.url || src;
            } else if (BELOW_MEDIUM) {
                src = Image.attributes.formats?.medium?.url || src;
            } else if (BELOW_LARGE) {
                src = Image.attributes.formats?.large?.url || src;
            }
        }
    }
    let finalSrc = src;
    if (isExternal) {
        finalSrc = `${process.env.REACT_APP_API_DOMAIN}${src}`;
    }else{
        finalSrc = Image;
    }
    if (!src && !finalSrc) {
        return <span className={`${className} bg-gray-300 text-white text-xl`}>Image Not Available</span>
    }
    return  <div style={{ '--image-url': `url(${finalSrc})` }} className={"bg-[image:var(--image-url)] " + className } {...props} >{children && children}</div>
}
export default BackgroundImage;

