import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router';
import * as bluebanner from '../../assets/topblue_pattern3.jpg'
import Loader from 'component/Loader';
import './style.css'
import * as lc4 from '../../assets/lakecarolina-background-4.jpg';
import descBg from 'assets/lc_green_pattern.jpg';


const FloorDetailsPage = ({ details }) => {
  const match = useRouteMatch();
  const { homeid } = match.params.homeid? match.params : details;
  console.log(homeid);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_DOMAIN}/api/inventories?filters[id][$eq]=${homeid}`)
      .then(resp => resp.json())
      .then(newData => {
        if (newData && newData.data.length > 0) {
          setData(newData.data);
          setIsLoading(false);
        }
      }).catch(error => {
        setData([]);
        setIsLoading(false);
      });
  }, [homeid]);

  if (isLoading) {
    return <Loader></Loader>
  }


  return (
    <main
      className="px-4 pb-16 max-w-screen-xl mx-auto"
      style={{ '--space': 'var(--s4)' }}
    >
      <img className='fixed h-full w-full top-0 left-0 z-[-1]' src={lc4}></img>
      <img className="border-top-banner absolute top-0 left-0 w-full border-b-2 border-white" src={bluebanner}></img>
      <Header data={data} />
      <BodySection data={data} />
    </main>
  )
}

const Header = ({ data }) => {
  return <div className="inline-flex w-full h-88 mt-7.5">
    <div className="flex-2 w-32"></div>
    <div className="flex-2 w-32"></div>
    <div className="flex-2 bg-lime-300 w-60 border-x-2 border-white">
      <div className='p-8 font-bold text-xl'>{data[0].attributes.name}</div></div>
    <div style={{'--image-url': `url(${descBg})`}}  className="flex-2 w-100 border-r-2 border-white bg-[image:var(--image-url)]"></div>
    <div className="flex-1"></div>
  </div>
}

const BodySection = ({ data }) => {
  return <div className='inline-flex h-auto w-full mt-[-6px]'>
    <div className="flex-2 w-32"></div>
    <div className="flex-2 w-32"></div>
    <div className='flex-2 w-100 bg-lime-300 border-white border-y-2 border-l-2 text-sm'>
      <div className='p-5'>
        <div className='flex flex-row'>
          <div className='font-bold w-36 text-right mr-2'>Address:</div>
          <div className='w-72'>{data[0].attributes.Address}</div>
        </div>
        <div className='flex flex-row'>
          <div className='font-bold w-36 text-right mr-2'>Square Footage:</div>
          <div className='w-72'>{data[0].attributes.SquareFootage}</div>
        </div>
        <div className='flex flex-row'>
          <div className='font-bold w-36 text-right mr-2'>Bedrooms:</div>
          <div className='w-72'>{data[0].attributes.Bedrooms}</div>
        </div>
        <div className='flex flex-row'>
          <div className='font-bold w-36 text-right mr-2'>Bethrooms:</div>
          <div className='w-72'>{data[0].attributes.Bathrooms}</div>
        </div>
        <div className='flex flex-row'>
          <div className='font-bold w-36 text-right mr-2'>Garage:</div>
          <div className='w-72'>{data[0].attributes.Garage}</div>
        </div>
      </div>
    </div>
    <div className='flex-2 w-60 bg-lime-300 border-white border-b-2 border-y-2 border-r-2'>
      <div className='p-5'>Details:</div>
    </div>
    <div className='flex-1'></div>
  </div>
}

export default FloorDetailsPage;
