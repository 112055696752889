import React, { useState } from 'react';
import { LazyLoadImage as Img } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import * as PinterestIcon from '../../assets/Pinterest-icon-small.png';
import * as FacebookIcon from '../../assets/Facebook-icon-small.png';
import * as TwitterIcon from '../../assets/Twitter-icon-small.png';


import BespokeLogo from "../../assets/bespoke-footer-icon.jpg";
import './style.css';
import FrameL from 'component/FrameL';

const Footer = ({ cmsData }) => {
    const { footerLinks } = cmsData.coreData.attributes;
    return <>
        <div className="relative p-4 pl-8 md:p-8 flex flex-row text-white items-center z-0 bg-gray-900">
            <div className='relative flex flex-row text-white items-center z-0 bg-gray-900 m-auto'>
            <Link to={footerLinks.pinterest} className='px-1' >
                <Img className='h-4 w-4' src={PinterestIcon} alt='PInterest' />
            </Link>
            <Link to={footerLinks.facebook} className='px-1' >
                <Img className='h-4 w-4' src={FacebookIcon} alt='Facebook' />
            </Link>
            <Link to={footerLinks.twitter} className='px-1' >
                <Img className='h-4 w-4' src={TwitterIcon} alt='Twitter' />
            </Link>
            <span className='text-xs px-2'>|</span>
            {footerLinks.links.map((link, i) => {
                return <>
                    <Link className='no-underline text-lime-600 font-bold text-xs' key={i} to={link.url}>{link.displayText}</Link>
                    {i != footerLinks.links.length - 1 && <span className='text-xs px-2'>|</span>}
                </>
            })}
            </div>
            <div className="max-w-[100px] ml-auto" >
                <Img src={BespokeLogo} alt="Bespoke Logo" />
            </div>
        </div>
    </>;
};

export default Footer;
