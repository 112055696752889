import FrameL from 'component/FrameL'
import { CmsDataWrapper } from 'context/cmsData'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as bluebanner from '../../assets/topblue_pattern3.jpg'
import * as btnImage from '../../assets/buttonBG.png'
import * as lc4 from '../../assets/lakecarolina-background-4.jpg';
import './style.css'




const SearchPage = ({ cmsData }) => {
  const [formData, setFormData] = useState({});
  const [searchResults, setSearchResults] = useState();

  function handleChange(e) {
    var fData = { ...formData }
    fData[e.target.name] = e.target.value;
    setFormData(fData);
  }
  function doSearch() {
    var searchUrl = `${process.env.REACT_APP_API_DOMAIN}/api/inventories?`;
    var searchFilters = "";
    for (var key in formData) {
      if (formData[key] != 0) {
        if (key == "SquareFootage") {
          var range = formData[key].split('-');
          if (!range[1]) {
            searchFilters += `filters[${key}][$gte]=${range[0]}&`;
          } else {
            searchFilters += `filters[${key}][$gte]=${range[0]}&filters[${key}][$lte]=${range[1]}&`;
          }
        } else {
          searchFilters += `filters[${key}][$eq]=${formData[key]}&`;
        }
      }
    }
    searchFilters += "populate=deep";
    searchUrl += searchFilters
    fetch(searchUrl).then(resp => resp.json()).then((resp => {
      console.log(resp);
      setSearchResults(resp);
    }))
    console.log("searhing", formData, searchFilters);
  }

  return (
    <main 
      className={"px-4 pb-16 max-w-screen-xl mx-auto"}
      style={{ '--space': 'var(--s4)' }}

    >
      <img className='fixed h-full w-full top-0 left-0 z-[-1]' src={lc4}></img>
      <img className="border-top-banner absolute top-0 left-0 w-full border-b-2 border-white" src={bluebanner}></img>
      <div className="inline-flex w-full h-96 bg-blue-900 mt-7.5">
        <div className="flex-2 w-32"></div>
        <div className="flex-2 w-32"></div>
        <div className="flex-2 bg-blue-400 w-60 border-x-2 border-white">
          <div className="search-container p-1 text-center">
            <div className='text-center text-sm font-bold text-white mb-3'>Floor Plan Search</div>
            <select className='mb-2 h-5 text-sm leading-3' name="SquareFootage" onChange={handleChange}  >
              <option value="0">Square Footage</option>
              <option value="0-1500">&lt; 15,00</option>
              <option value="1501-2000">1,501 - 2,000</option>
              <option value="2001-2500">2,001 - 2,500</option>
              <option value="2501-3000">2,501 - 3,000</option>
              <option value="3001-3500">3,001 - 3,500</option>
              <option value="3501-4000">3,501 - 4,000</option>
              <option value="4001">4,000+</option>
            </select>
            <select className='mb-2 h-5 text-sm leading-3' name="Bedrooms" onChange={handleChange}>
              <option value="0">Bedrooms</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <select className='mb-2 h-5 text-sm leading-3' name="Bathrooms" onChange={handleChange}>
              <option value="0">Bathrooms</option>
              <option value="1">1</option>
              <option value="1.5">1.5</option>
              <option value="2">2</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
              <option value="3.5">3.5</option>
              <option value="4">4</option>
            </select>
            <select className='mb-2 h-5 text-sm leading-3' name="Stories" onChange={handleChange}>
              <option value="0">Stories</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
            <select className='mb-2 h-5 text-sm leading-3' name="Garage" onChange={handleChange}>
              <option value="0">Garage</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
            <select className='mb-2 h-5 text-sm leading-3' name="MasterBedrooms" onChange={handleChange}>
              <option value="0">Master Bedroom</option>
              <option value="Upstairs">Upstairs</option>
              <option value="Downstairs">Downstairs</option>
            </select>
            <div className='search-button mt-14 leading-20' style={{ backgroundImage: 'url(' + btnImage + ')' }} onClick={doSearch}>Go</div>
          </div>
        </div>
        <div className="flex-2 w-120 bg-blue-950 border-r-2 border-white">
          <div className='text-white h-32 w-110 bg-blue-900 mx-auto mt-52 text-base p-2.5'>Build Your Next Home With Our New Home Builders: Mungo Homes & Hurricane Builders! Or let us help you with a Custom Homebuilder Agreement.</div>
        </div>
        <div className="flex-1"></div>
      </div>

      <section>
        <div className='h-auto bg-black w-full text-white p-5'>
          {!searchResults && <div>
            Find a new home design & floor plan style that fits your lifestyle
            Start your tour of Lake Carolina at the Sales Center, where you can pick up community event calendars, maps and information on available properties in Lake Carolina. Plus, we always have coffee, tea and ice cold bottled water if you need a little refreshment!

            Lake Carolina is a master-planned community with a private 200 acre freshwater lake, six miles of shoreline, thirty miles of winding bike trails, pools, tennis courts, Community Town Center, award-winning schools within Lake Carolina, and YMCA.

            Happily, Lake Carolina has beautiful new homes for most budgets. What really sets the community apart is the lake lifestyle and amenities that simply can't be found elsewhere. Our residents live in surroundings most people only get to enjoy on vacation.

            If you prefer to build your custom dream home, lakefront home sites with fabulous lake views are available, as well as gorgeous, heavily wooded interior home sites. Lake Carolina offers the finest in Columbia South Carolina real estate with over 30 neighborhoods to fit your lifestyle.

            You will find the Lake Carolina Properties Sales Center in the heart of Lake Carolina's Town Center across from Market Park.  The address is 5 Lake Carolina Way (suite 120) Columbia, SC 29229.  Make your first left as you enter the main entrance off of Hard Scrabble Road, turn left BEFORE you reach the fountain circle.  (803) 736-5253
          </div>}
          {(searchResults && searchResults.meta.pagination.total > 0) &&
            <SearchResultsView results={searchResults} ></SearchResultsView>
          }

        </div>

      </section>
    </main>
  )
}

const SearchResultsView = ({ results }) => {
  return (
    <div className='flex flex-wrap'>
      {results.data.map((home, i) => {
        return <HomeBox key={i} id={home.id} homeData={home.attributes} />
      })}
    </div>
  )
}

const HomeBox = ({ id, homeData }) => {
  return (
    <div className='h-60 w-60 bg-white m-5'>
      <div className='h-44 w-60 bg-gray-500'>
        <FrameL className='h-full w-full' Image={homeData.photo.data} ></FrameL>
      </div>
      <div className='text-black text-xs h-10 px-5'>{homeData.name || "home"+id}</div>
      <div className='text-right px-5'>
        <Link to={`/pages/homes/${id}`} className="text-right text-black text-xs h-6">Details &gt;</Link>
      </div>
    </div>)

}

export default CmsDataWrapper(SearchPage, ['home', 'core-data'])
